<template>
  <v-container fluid>
    <v-row class="ma-1">
      <v-col cols="12" md="12" lg="12" sm="12">
        <v-tabs
          slider-color="yellow"
          v-model="tab"
          centered
          stacked
          show-arrows
          scrollable
          danse
        >
          <v-tab @click="setlistpasien()"> List Pasien Buka </v-tab>
          <v-tab @click="setPasienTutup()"> List Pasien Tutup </v-tab>
          <v-tab v-if="pegawai.is_dokter == 1"> RABER/ALIH RAWAT </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-title>
                <h5 class="mt-1 primary--text">
                  <v-icon class="primary--text" medium
                    >mdi-hospital-building</v-icon
                  >
                  <span class="mt-2">Layanan Rawat Inap</span>
                </h5>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="4" sm="4" md="4">
                    <v-text-field
                      v-model="dataTable.search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                      label="Cari Berdasarkan No.RM/Nama Pasien/Nama Dokter"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2" sm="2" md="2">
                    <v-select
                      dense
                      v-model="dataTable.status"
                      :items="statusitem"
                      @change="statuspasien()"
                      label="Status Pasien"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="2" lg="2" sm="2">
                    <v-select
                      dense
                      v-model="rbulan"
                      @change="bulanchange()"
                      :items="rentangbulan"
                      label="Rentang Bulan"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-data-table
                  :loading="dataTable.isLoading"
                  :headers="dataTable.headerData"
                  :search="dataTable.search"
                  :items="filteredResources"
                  :sort-by.sync="dataTable.config.table.sortBy"
                  :sort-desc.sync="dataTable.config.table.sortDesc"
                  :items-per-page="dataTable.config.table.itemsPerPage"
                  :page.sync="dataTable.config.table.page"
                  :server-items-length="dataTable.serverLength"
                  :options.sync="dataTable.options"
                  height="440pt"
                  item-key="id"
                  multi-sort
                  hide-default-footer
                  fixed-header
                  @page-count="dataTable.config.table.pageCount = $event"
                  @pagination="dataTable.pagination = $event"
                >
                  <template #[`item.birth_date`]="{ item }">
                    {{ item.birth_date | moment("DD MMMM YYYY") }}
                  </template>
                  <template #[`item.staff_name`]="{ item }">
                    {{ item.staff_name }}
                    <br />
                    <v-chip
                      v-if="item.namaDokterRaber"
                      dark
                      color="pink lighten-3"
                    >
                      Raber/Konsul : {{ item.namaDokterRaber }}
                    </v-chip>
                  </template>
                  <template #[`item.no`]="{ index }">
                    {{
                      index +
                      (dataTable.config.table.page - 1) *
                        dataTable.config.table.itemsPerPage +
                      1
                    }}
                  </template>
                  <template #[`item.service_date`]="{ item }">
                    {{ item.service_date | moment("DD MMMM YYYY") }}
                  </template>
                  <template #[`item.patient_name`]="{ item }">
                    {{ item.salutation }} {{ item.patient_name }}
                  </template>

                  <template #[`item.folio_status`]="{ item }">
                    <div v-if="item.folio_status == '1'">
                      <v-chip color="green" dark> Reservasi </v-chip>
                    </div>
                    <div v-if="item.folio_status == '3'">
                      <v-chip color="blue" dark> Buka </v-chip>
                    </div>
                    <div v-if="item.folio_status == '4'">
                      <v-chip color="red" dark> Tutup </v-chip>
                    </div>
                    <div v-if="item.folio_status == '5'">Batal</div>
                  </template>
                  <template #[`item.unit_name`]="{ item }">
                    <v-chip dark :color="setcolor(item.hari)">
                      {{ item.unit_name }} | &nbsp;<b>Hari Rawatan Ke </b>:
                      {{ item.hari == 0 ? "1" : item.hari }} Hari
                    </v-chip>
                  </template>

                  <template #[`item.aksi`]="{ item }">
                    <v-btn
                      x-small
                      fab
                      dark
                      color="primary"
                      @click="setPasien(item)"
                    >
                      <v-icon>mdi-check-circle-outline</v-icon> </v-btn
                    >&nbsp;<v-btn
                      x-small
                      fab
                      dark
                      color="#E53935"
                      @click="buatBdrs(item)"
                    >
                      <v-icon>mdi-water-plus</v-icon> </v-btn
                    >&nbsp;<v-btn
                      x-small
                      fab
                      dark
                      color="green"
                      @click="formSurve(item)"
                    >
                      <v-icon>mdi-bacteria</v-icon>
                    </v-btn>
                    <!-- <v-btn
                  x-small
                  fab
                  dark

                  color="#E53935"
                  @click="orderLabor(item)"
                >
                  <v-icon>mdi-needle</v-icon>
                </v-btn>
                &nbsp;
                <v-btn
                  x-small
                  fab
                  dark

                  color="orange"
                  @click="orderRadiologi(item)"
                >
                  <v-icon>mdi-radioactive</v-icon> </v-btn
                > -->

                    <!--

                 &nbsp;<v-btn
                x-small
                fab
                outlined
                color="#7B1FA2"
                @click="identifikasiPasien(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn> -->
                  </template>
                </v-data-table>
                <div class="row align-center pb-3 m">
                  <div class="col-md-6 col-12 order-md-0 order-1 pt-0 pt-md-4">
                    <v-data-footer
                      class="float-left"
                      :pagination="dataTable.pagination"
                      :prev-icon="null"
                      :next-icon="null"
                      :first-icon="null"
                      :last-icon="null"
                      :items-per-page-options="[10, 15, 50, 100, -1]"
                      :options.sync="dataTable.options"
                    />
                  </div>
                  <div
                    class="col-md-6 col-12 order-md-1 order-0 mt-4 pb-0 pb-md-4"
                  >
                    <v-pagination
                      v-model="dataTable.config.table.page"
                      :length="dataTable.config.table.pageCount"
                      total-visible="7"
                      circle
                    />
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>
                <h5 class="mt-1 primary--text">
                  <v-icon class="primary--text" medium
                    >mdi-hospital-building</v-icon
                  >
                  <span>Layanan Rawat Inap Sudah Closed</span>
                </h5>
              </v-card-title>
              <v-card-text>
                <DataPasienClose />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <Raber />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <!-- <v-col cols="12" md="4">
          <v-card class="mr-3">
            <v-list color="#2c698d" dark dense flat>
              <h4 class="pl-4 pt-4 pb-2">
                Form Rekam Medis {{ nama_pasien }} ({{ rm }})
              </h4>
              <h5 class="pl-4 pt-1 pb-2">
                Tanggal Lahir
                {{ pasienSet.birth_date | moment("DD MMMM YYYY") }}
              </h5>
              <v-list-item-group v-model="model" color="red">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="item.click"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col> -->
    </v-row>
    <FormSurve
      ref="formSurve"
      :show-dialog="dialogShowSurv"
      :negative-button="dialogActionNBtnSurv"
      :disabled-negative-btn="dialogDisableNBtnSurv"
      :progress="dialogProgressSurv"
      :title="dialogTitleSurv"
      :message="dialogMessageSurv"
      :dataPatient="dataPatient"
    />
    <IdentifikasiPasien
      ref="iden"
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
      :memberCard="memberCardV"
      :dataPatient="dataPatient"
    />
    <v-snackbar v-model="snackbar">
      {{ snack_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

  <!-- // notif -->
</template>

<script>
import { apiKedua, errorMsg } from '../../plugins/supports'
import FormSurve from '@/components/FormSurve'
import Raber from './Raber.vue'
import DataPasienClose from './DataPasienClose.vue'
import IdentifikasiPasien from '@/components/IdentifikasiPasien'
// import FormLabor from '@/components/DialogLabor'
// import FormRadiologi from '@/components/DialogRadiologi'
// eslint-disable-next-line no-unused-vars
// import FormBdrs from '../bdrs/FormBdrs'

export default {
  components: {
    FormSurve,
    IdentifikasiPasien,
    // FormLabor,
    // FormBdrs,
    Raber,
    DataPasienClose
    // FormRadiologi
  },
  data () {
    return {
      rentangbulan: [
        '1 Minggu',
        '1 BULAN',
        '2 BULAN',
        '3 BULAN',
        '6 BULAN',
        '1 TAHUN'
      ],
      kerjakan: false,
      sudah_di_kerjakan: [],
      rbulan: '1 Minggu',
      myStylePasien: {
        backgroundColor: '#16a085'
      },
      statusitem: ['BUKA', 'TUTUP', 'RESERVASI'],
      status: '',
      tab: '',
      // diaglog labor
      dialogShowLabor: false,
      dialogTitleLabor: 'Permintaan Laboratorium',
      dialogProgressLabor: false,
      dialogDisableNBtnLabor: false,
      dialogDisablePBtnLabor: false,

      dialogActionNBtnLabor: () => {
        this.dialogShowLabor = false
      },
      dialogActionPBtnLabor: () => {},

      // Dialog Radiologi
      dialogActionNBtnRadiologi: () => {
        this.dialogShowRadiologi = false
      },
      dialogActionPBtnRadiologi: () => {},

      // Dialog Labor
      dialogActionNBtnBdrs: () => {
        this.dialogShowBdrs = false
      },
      dialogActionPBtnBdrs: () => {},

      dialogShowRadiologi: false,
      dialogShowBdrs: false,
      dialogTitleRadiologi: 'Form Permintaan Radiologi',
      dialogTitleBdrs: 'Form Permintaan Darah',
      dialogMessageRadiologi: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgressRadiologi: false,
      memberCardRadiologi: '',
      dialogDisableNBtnRadiologi: false,
      dialogDisablePBtnRadiologi: false,
      dialogDisableNBtnBdrs: false,
      dialogDisablePBtnBdrs: false,
      itemSeluruh: '',

      dialogShow: false,
      dialogTitle: 'SKRINING PASIEN',
      dialogMessage: 'UNTUK MANAJER PELAYANAN PASIEN',
      dialogProgress: false,
      memberCardV: '',
      dialogDisableNBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogShowSurv: false,
      dialogTitleSurv: 'SURVEILEN HAIs RUANG PERAWATAN EXECUTIVE',
      dialogMessageSurv: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgressSurv: false,
      dataPatient: [],
      dataPatientForm: '',

      dialogDisableNBtnSurv: false,
      dialogActionNBtnSurv: () => {
        this.dialogShowSurv = false
      },
      patient: [],
      iReadonly: false,
      pegawai: '',
      subUnitId: '',
      employeeId: '',
      dataTable: {
        isLoading: false,
        headerData: [
          { text: 'Aksi', value: 'aksi', width: '190px', align: 'center' },
          { text: 'Status', value: 'folio_status', width: '100px' },
          { text: 'Nama Pasien', value: 'patient_name', width: '270px' },
          { text: 'DPJP', value: 'staff_name', width: '250px' },
          { text: 'Nama Ruangan', value: 'unit_name', width: '290px' },
          { text: 'Tanggal Masuk', value: 'service_date', width: '170px' },
          { text: 'No. RM', value: 'medrec_no', width: '120px' },
          { text: 'Cara Bayar', value: 'pay_by_name', width: '250px' },
          { text: 'No. Asuransi/Bpjs', value: 'member_card', width: '150px' },
          { text: 'No. Registrasi', value: 'registration_no', width: '150px' }
        ],
        search: '',
        status: '',
        tglPelayanan: '',

        datas: [],
        config: {
          table: {
            page: 1,
            pageCount: 0,
            sortBy: ['service_date'],
            sortDesc: [],
            itemsPerPage: 1500,
            itemKey: 'id'
          }
        },
        serverLength: 0,
        pagination: {},
        options: {}
      },

      model: 1,
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      folioYangDipakai: '',
      rm: '',
      reg: '',
      folio: '',
      sub_unit_id: '',
      nama_pasien: '',
      pasienSet: ''
    }
  },
  mounted () {
    this.bul = '1 Minggu'

    // localStorage.setItem('drawer', 'false')
    // localStorage.setItem('minivar', 'false')
  },
  methods: {
    bulanchange () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.rbulan
      })
    },
    muatulang () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.rbulan
      })
    },
    setPasienTutup () {},
    setlistpasien () {
      if (this.employeeId !== 'undifined') {
        this.getDataPasien({
          id_pegawai: this.pegawai.employee_id,
          // eslint-disable-next-line eqeqeq
          is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
          ...this.dataTable.options,

          search: this.dataTable.search
        })
      }
    },
    buatBdrs (item) {
      console.log(item)
      this.$router.replace({
        name: 'DataPasienRawatInap',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowBdrs = true
        // this.dataPatient = this.item
        this.$refs.bdrs.getDataPasien(this.$route.query.no_rm)
        this.$refs.bdrs.getDataListBankDarah(this.$route.query.no_rm)
      }, 1000)
    },
    formSurve (v) {
      this.dialogProgressSurv = true

      setTimeout(() => {
        this.dialogProgressSurv = false

        this.dialogShowSurv = true
        this.dataPatient = v
        this.dialogTitleSurv = 'SURVEILEN HAIs RUANG PERAWATAN ' + v.unit_name
        this.$refs.formSurve.patientData()
      }, 1000)
    },
    setcolor (hari) {
      if (hari >= 5) {
        return 'red darken-1'
      } else {
        return 'teal lighten-1'
      }
    },
    itemRowBackground (folioIdNya) {
      if (this.sudah_di_kerjakan.includes(folioIdNya)) {
        return 'green'
      } else {
        return 'red'
      }
    },
    identifikasiPasien (v) {
      this.dialogShow = true
      localStorage.removeItem('identitas')
      localStorage.setItem('identitas', JSON.stringify(v))

      setTimeout(() => {
        // console.log('HASILLLL' + v.folio_id)

        this.dataPatient = v
        this.$refs.iden.getData()
      }, 1000)
    },
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },

    getColor (v) {
      // return new Promise((resolve, reject) => {
      // })
    },
    getDataPasien (v) {
      this.dataTable.isLoading = true
      // console.log(v)
      const query = new URLSearchParams(v).toString()
      console.log(query)

      apiKedua
        .get(`rawat-inap/get-data-in?${query}`)
        .then((response) => {
          this.dataTable.datas = response.data.results
            ? response.data.results.data ?? []
            : []
          this.dataTable.serverLength = response.data.results
            ? parseInt(response.data.results.total) ?? 0
            : 0
          this.isReadonly = false
          this.dataTable.isLoading = false
        })
        .catch((error) => {
          this.isReadonly = false
          this.dataTable.isLoading = false

          console.log(error)
        })
    },
    orderLabor (item) {
      this.$router.replace({
        name: 'DataPasienRawatInap',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowLabor = true
        this.$refs.labor.historyLabor(this.$route.query.no_rm)
      }, 1000)
    },
    statusChange () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.bulanchange
      })
    },
    orderRadiologi (item) {
      this.$router.replace({
        name: 'DataPasienRawatInap',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowRadiologi = true
        this.$refs.radiologi.historyRadiologi(this.$route.query.no_rm)
      }, 1000)
    },
    cppt () {},
    setPasien (item) {
      // console.log(item)

      this.$router.push({
        name: 'CPPTRAWATINAP',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
    }
  },
  watch: {
    'dataTable.options' (a, b) {
      if (this.employeeId) {
        setTimeout(() => {
          this.getDataPasien({
            id_pegawai: this.pegawai.employee_id,
            // eslint-disable-next-line eqeqeq
            is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
            ...this.dataTable.options,
            search: this.dataTable.search,
            status: this.dataTable.status,
            bulan: this.rbulan
          })
        }, 100)
      }
    },
    'dataTable.search' (a, b) {
      setTimeout(() => {
        if (this.employeeId) {
          if (this.dataTable.search.length >= 3) {
            this.getDataPasien({
              id_pegawai: this.pegawai.employee_id,
              // eslint-disable-next-line eqeqeq
              is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
              ...this.dataTable.options,
              search: this.dataTable.search,
              status: this.dataTable.status,
              bulan: this.rbulan
            })
          } else {
            errorMsg('Tidak Mencari Jika Tidak Lebih Dari 2 Kata')
          }
        }
      }, 100)
    }
    // 'dataTable.status' (a, b) {
    //   setTimeout(() => {
    //     if (this.employeeId !== 'undifined') {
    //       this.getDataPasien({
    //         id_pegawai: this.pegawai.employee_id,
    //         // eslint-disable-next-line eqeqeq
    //         is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
    //         ...this.dataTable.options,
    //         search: this.dataTable.search,
    //         status: this.dataTable.status
    //       })
    //     }
    //   }, 100)
    // }
  },
  created () {
    this.dataTable.status = 'BUKA'
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
        this.subUnitId = this.pegawai.sub_unit_id
        this.employeeId = this.pegawai.employee_id
        if (this.employeeId) {
          this.getDataPasien({
            id_pegawai: this.pegawai.employee_id,
            // eslint-disable-next-line eqeqeq
            is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
            ...this.dataTable.options,

            search: this.dataTable.search,
            status: this.dataTable.status,
            bulan: this.rbulan
          })
        }

        // console.log(this.subUnitId)
      }, 100)
    }
  },
  computed: {
    filteredResources () {
      // console.log('123213123')
      if (this.dataTable.status) {
        console.log()
        return this.dataTable.datas.filter((item) => {
          const status = this.dataTable.status
            ? item.folio_status_convert
              .toLowerCase()
              .includes(this.dataTable.status.toLowerCase())
            : false

          return status
        })
      } else {
        return this.dataTable.status
      }
    }
  }
}
</script>
