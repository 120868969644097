
<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :persistent="persistentDialog"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }} &nbsp;
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>
          <v-row>
            <v-col cols="11" sm="5">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Pilih Bulan"
                    prepend-icon="mdi-calendar"
                    readonly

                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" type="month" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary"  @click="buatTanggal">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>

          </v-row>
        </v-card-title>
        <v-card-text>
          Cara Bayar
          <div
            style="font-size: 17px"
            class="mb-3"
            v-html="dataPatient.pay_by_name"
          />
          <h2>
            {{ dataPatient.medrec_no }}/{{ dataPatient.salutation }}
            {{ dataPatient.patient_name }}/({{
              dataPatient.birth_date | moment("DD MMMM YYYY")
            }})
          </h2>

          <v-progress-linear
            v-show="progress"
            color="primary"
            indeterminate
            rounded
            height="6"
          />

          <v-simple-table elevation="5" dense fixed-header height="700px">
            <thead>
              <tr>
                <th class="text-left" rowspan="2">Diagnosa</th>
                <th class="text-left" rowspan="2">Bulan Dan Tahun</th>
                <th class="text-center" colspan="31">Tanggal Pemeriksaan</th>
              </tr>
              <tr>
                <th
                  style="min-width: 150px !important"
                  class="text-center"
                  v-for="(n, i) in 31"
                  :key="i"
                >
                  {{ n }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formSurv" :key="index">
                <td
                  style="min-width: 220px !important"
                  class="lebar"
                  width="30%"
                >
                  <v-text-field
                    label="Diagnosa"
                    @blur="simpanDiagnosa(item.id_emr_surveilen_hais)"
                    v-model="item.diagnosa"
                    outlined
                    dense
                  ></v-text-field>
                </td>
                <td
                  style="min-width: 220px !important"
                  class="lebar"
                  width="30%"
                >
                  Bulan {{ item.bulan }} - Tahun : {{ item.tahun }}
                </td>
                <td
                  style="min-width: 220px !important"
                  class="lebar"
                  v-for="(s, n) in JSON.parse(item.detail_pemeriksaan)"
                  :key="n"
                >
                  <div class="mt-2">
                    <p>PN : {{ s.PN }}</p>
                    <p>Antiobotik : {{ s.Antiobotik }}</p>
                    <p>Dosis : {{ s.Dosis }}</p>
                    <p>Kultur : {{ s.Kultur }}</p>
                    <p>IVL : {{ s.IVL }}</p>
                    <p>UC : {{ s.UC }}</p>
                    <span>ETT VENTI : {{ s.ETT_VENTI }}</span
                    ><br />
                    <span>CVL : {{ s.CVL }}</span
                    ><br />
                    <span>TIND OK : {{ s.TIND_OK }}</span
                    ><br />
                    <span>PLEBITIS : {{ s.PLEBITIS }}</span
                    ><br />
                    <span>ISK : {{ s.ISK }}</span
                    ><br />
                    <span>VAP : {{ s.VAP }}</span
                    ><br />
                    <span>IAD : {{ s.IAD }}</span
                    ><br />
                    <span>IDO : {{ s.IDO }}</span
                    ><br />
                    <span>DEKUBITUS : {{ s.DEKUBITUS }}</span
                    ><br />
                    <v-btn
                      color="success"
                      class="mt-1"
                      @click="edit(s, item.id_emr_surveilen_hais, n)"
                      small
                      >Edit</v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formDiaglog" max-width="800px">
      <v-card>
        <v-card-title>
          <span>Form Input {{ detailForm.tgl }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="mt-1">
          <v-row>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                label="Tanggal"
                dense
                readonly
                outlined
                v-model="detailForm.tgl"
              />
              <v-text-field
                label="PN"
                readonly
                dense
                outlined
                v-model="detailForm.PN"
              />
              <v-text-field
                label="Antibiotik"
                dense
                outlined
                v-model="detailForm.Antiobotik"
              />
              <v-text-field
                label="Dosis"
                dense
                outlined
                v-model="detailForm.Dosis"
              />
              <v-text-field
                label="Kultur"
                dense
                outlined
                v-model="detailForm.Kultur"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                label="IVL"
                dense
                outlined
                v-model="detailForm.IVL"
              />
              <v-text-field label="DC" dense outlined v-model="detailForm.UC" />
              <v-text-field
                label="ETT VENTI"
                dense
                outlined
                v-model="detailForm.ETT_VENTI"
              />
              <v-text-field
                label="CVL"
                dense
                outlined
                v-model="detailForm.CVL"
              />
              <v-text-field
                label="TIND OK"
                dense
                outlined
                v-model="detailForm.TIND_OK"
              />
              <v-text-field
                label="PLEBITIS"
                dense
                outlined
                v-model="detailForm.PLEBITIS"
              />
            </v-col>
            <v-col cols="12" md="4" lg="4">
              <v-text-field
                label="ISK"
                dense
                outlined
                v-model="detailForm.ISK"
              />
              <v-text-field
                label="VAP"
                dense
                outlined
                v-model="detailForm.VAP"
              />
              <v-text-field
                label="IAD"
                dense
                outlined
                v-model="detailForm.IAD"
              />
              <v-text-field
                label="IDO"
                dense
                outlined
                v-model="detailForm.IDO"
              />
              <v-text-field
                label="DEKUBITUS"
                dense
                outlined
                v-model="detailForm.DEKUBITUS"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" text @click="simpan"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { apiKedua } from '../plugins/supports'
// eslint-disable-next-line no-unused-vars
import { apiKedua, successMsg, baseUrl, errorMsg } from '../plugins/supports'

export default {
  name: 'FormSurve',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tutup',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    message: {
      type: String,
      default: '',
      required: true
    },
    progress: {
      type: Boolean,
      default: false,
      required: false
    },
    memberCard: {
      type: String,
      default: '',
      required: false
    },
    dataPatient: {
      type: [],
      default: [],
      required: false
    }
  },
  data () {
    return {
      nama: '',
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,

      tglCetakKartu: '',
      statusPeserta: '',
      provUmum: '',
      nomorSep: '',
      tglSep: '',
      noRujukan: '',
      poli: '',
      diagnosa: '',
      tglRujukan: '',
      formSurv: [],
      id_detail: '',
      tgl_surve: '',
      detailForm: {
        tgl: '',
        PN: '',
        Antiobotik: '',
        Dosis: '',
        Kultur: '',
        IVL: 0,
        UC: 0,
        ETT_VENTI: 0,
        CVL: 0,
        TIND_OK: 0,
        PLEBITIS: 0,
        ISK: 0,
        VAP: 0,
        IAD: 0,
        IDO: 0,
        HAP: 0,
        DEKUBITUS: 0
      },
      s: [],
      models: [],
      pemakai: '',
      formDiaglog: false
    }
  },
  methods: {
    patientData () {
      const d = new Date()
      const month = d.getMonth() + 1
      console.log(month)
      setTimeout(() => {
        apiKedua
          .get(
            'surv/generate-surve?no_mr=' +
              this.dataPatient.medrec_no +
              '&bulan=' +
              month +
              '&asal_ruangan=' +
              this.dataPatient.unit_id +
              '&crt=' +
              this.pemakai.employee_id
          )
          .then((r) => {
            //   console.log(r)
            // eslint-disable-next-line no-unused-vars
            const { con, msg, results } = r.data

            if (con) {
              //   console.log(results)
              this.formSurv = results
            } else {
              this.formSurv = []
              errorMsg('Data Hais Belum Ada, Silahkan Buat Terlebih Dahulu , Formnya')
            }
          }, 1000)
      })
      //   console.log('DATA PATIENT DARI INDEX ' + this.dataPatient.patient_name)
    },
    buatTanggal () {
      console.log(this.date)
      // eslint-disable-next-line no-unused-vars
      var data = {
        no_rm: this.dataPatient.medrec_no,
        bt: this.date,
        asal_ruangan: this.dataPatient.unit_id,
        crt: this.pemakai.employee_id
      }
      const query = new URLSearchParams(data).toString()

      // eslint-disable-next-line no-undef
      $.get(baseUrl + `surv/buat-form-surve?${query}`, (response) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = response
        if (con) {
          successMsg(msg)
          this.patientData()
        } else {
          errorMsg(msg)
        }
        if (con) {
        } else {

        }
      }, 'JSON')
    },
    edit (v, id, t) {
      console.log('FORM' + v)
      // this.detailForm.PN = this.pemakai.employee_name
      v.PN = this.pemakai.employee_name
      this.detailForm = v
      this.formDiaglog = true
      this.id_detail = id
      this.tgl_surve = t
    },
    simpan () {
      const formData = new FormData()
      console.log(formData)

      var data = {
        id: this.id_detail,
        form: this.detailForm,
        tgl: this.tgl_surve,
        upt: this.pemakai.employee_id
      }
      formData.append(
        'data',
        new Blob([JSON.stringify(data)], {
          type: 'application/json'
        })
      )
      apiKedua
        .post('surv/update-form-surve', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          const { con, results } = response.data
          if (con) {
            console.log(results)
            this.formDiaglog = false
            this.patientData()
          } else {
            this.formDiaglog = false
            this.patientData()
          }
        })
      //   console.log('DETAIL FORM ' + this.detailForm.TIND_OK)
    },
    simpanDiagnosa (id) {
      console.log(id)
      console.log(this.formSurv)

      const formData = new FormData()
      console.log(formData)
      localStorage.removeItem('form-resume-rawat-jalan')

      var data = {
        id: id,
        form: this.formSurv[0],
        upt: this.pemakai.employee_id
      }
      formData.append(
        'data',
        new Blob([JSON.stringify(data)], {
          type: 'application/json'
        })
      )
      apiKedua
        .post('surv/diagnosa-update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          const { con, msg, results } = response.data
          if (con) {
            console.log(results)
            successMsg(msg)
            this.patientData()
          } else {
            this.formDiaglog = false
            errorMsg(msg)
            this.patientData()
          }
        })
    }
  },
  created () {
    // console.log('DARI PROPS' + this.memberCard)
    setTimeout(() => {
      this.pemakai = JSON.parse(localStorage.getItem('user'))
    }, 1000)
  }
}
</script>

<style scoped>
table,
.lebar {
  table-layout: fixed;
  width: 280px;
}
</style>
