
<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :persistent="persistentDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div style="font-size: 17px" class="mb-1" v-html="message" />
          <v-progress-linear
            v-show="progress"
            color="primary"
            indeterminate
            rounded
            height="6"
          />

          <v-simple-table dense fixed-header height="500px" class="mt-2">
            <thead>
              <tr>
                <th rowspan="2" width="5%" class="text-center">No</th>
                <th rowspan="2" class="text-center" width="50%">Kriteria</th>
                <th colspan="2" class="text-center">Masalah</th>
                <th rowspan="2" width="170px" class="text-center">Tanggal</th>
              </tr>
              <tr>
                <th class="text-center">Ya</th>
                <th class="text-center">Tidak</th>
              </tr>
            </thead>
            <tbody v-for="(item, n) in skrining" :key="n">
              <tr>
                <td>#</td>
                <td>
                  {{ item.label }}
                </td>
                <td colspan="2">
                  <v-radio-group v-model="item.value">
                    <v-layout>
                      <v-flex>
                        <v-radio value="Ya">
                          <template v-slot:label>
                            <div>
                              YA
                              <strong
                                ><v-icon class="success--text"
                                  >mdi-check</v-icon
                                ></strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-flex>
                      <v-flex>
                        <v-radio value="Tidak">
                          <template v-slot:label>
                            <div>
                              Tidak
                              <strong
                                ><v-icon class="error--text"
                                  >mdi-cancel</v-icon
                                ></strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </td>
                <td>
                  <v-text-field
                    type="date"
                    dense
                    class="pt-4"
                    label="Tanggal Temuan"
                    v-model="item.tgl_temuan"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>
          <v-btn color="green darken-1" text @click="simpan()">
            {{ positiveBtnLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { apiKedua } from '../plugins/supports'

export default {
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    message: {
      type: String,
      default: '',
      required: true
    },
    progress: {
      type: Boolean,
      default: false,
      required: false
    },
    dataPatient: {
      type: [],
      default: [],
      required: false
    }
  },
  data () {
    return {
      skrining: [],
      checked: [],
      checkedTidak: [],
      tanggal: [],
      data: {},
      folioYangDipakai: [],
      pegawai: [],
      patient: []
    }
  },
  methods: {
    getData () {
      const skrining = []
      console.log('PATIENT ' + this.patient)
      if (localStorage.getItem('identitas')) {
        this.patient = JSON.parse(localStorage.getItem('identitas'))
        this.pegawai = JSON.parse(localStorage.getItem('user'))
      }
      apiKedua.get('form-skrining/index?id=' + this.patient.folio_id).then((r) => {
        // console.log('Hasil' + r)
        const { con, results } = r.data

        if (con) {
          // console.log(results)
          //   resuls
          for (const item of results.data) {
            skrining.push({
              label: item.label,
              value: item.value,
              tgl_temuan: item.tgl_temuan
            })
            // console.log(item)
          }
        }
      })
      this.skrining = skrining
    },
    simpan () {
      if (localStorage.getItem('identitas')) {
        this.patient = JSON.parse(localStorage.getItem('identitas'))
        this.pegawai = JSON.parse(localStorage.getItem('user'))
      }
      var data = {
        identitas: this.patient,
        json: this.skrining,
        pegawai: this.pegawai
      }
      const formData = new FormData()
      formData.append(
        'data',
        new Blob([JSON.stringify(data)], {
          type: 'application/json'
        })
      )

      apiKedua.post('form-skrining/simpan', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          const { con, results } = response.data
          if (con) {
            console.log(results)
          } else {
          }
        })
      console.log(data)
    }
  },
  created () {

  }
}
</script>

  <style scoped>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
