<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" sm="4" md="4">
        <v-text-field
          append-icon="mdi-magnify"
          outlined
          @input="rubah()"
          dense
          v-model="dataTable.search"
          :disabled="isDisabled"
          label="Cari Berdasarkan No.RM/Nama Pasien/Nama Dokter"
        ></v-text-field>
      </v-col>
      <v-col col="12" lg="4" sm="4" md="4">
        <v-text-field
          append-icon="mdi-date"
          outlined
          dense
          v-model="dataTable.tglPelayanan"
          @change="changetanggal()"
          label="Cari Tanggal Pulang"
          type="date"
        >
        </v-text-field>
      </v-col>
      <v-col col="12" lg="4" sm="4" md="4">
        <v-text-field
          append-icon="mdi-date"
          outlined
          dense
          @change="changetanggal()"
          v-model="dataTable.tglPelayanan2"
          label="Cari Tanggal Pulang"
          type="date"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :loading="dataTable.isLoading"
      :headers="dataTable.headerData"
      :search="dataTable.search"
      :items="dataTable.datas"
      :sort-by.sync="dataTable.config.table.sortBy"
      :sort-desc.sync="dataTable.config.table.sortDesc"
      :items-per-page="dataTable.config.table.itemsPerPage"
      :page.sync="dataTable.config.table.page"
      :server-items-length="dataTable.serverLength"
      :options.sync="dataTable.options"
      height="440pt"
      item-key="id"
      multi-sort
      hide-default-footer
      fixed-header
      @page-count="dataTable.config.table.pageCount = $event"
      @pagination="dataTable.pagination = $event"
    >
      <template #[`item.birth_date`]="{ item }">
        {{ item.birth_date | moment("DD MMMM YYYY") }}
      </template>
      <template #[`item.staff_name`]="{ item }">
        {{ item.staff_name }}
        <br />
        <v-chip v-if="item.namaDokterRaber" dark color="pink lighten-3">
          Raber/Konsul : {{ item.namaDokterRaber }}
        </v-chip>
      </template>
      <template #[`item.no`]="{ index }">
        {{
          index +
          (dataTable.config.table.page - 1) *
            dataTable.config.table.itemsPerPage +
          1
        }}
      </template>
      <template #[`item.service_date`]="{ item }">
        {{ item.service_date | moment("DD MMMM YYYY") }}
      </template>
      <template #[`item.patient_name`]="{ item }">
        {{ item.salutation }} {{ item.patient_name }}
      </template>

      <template #[`item.folio_status`]="{ item }">
        <div v-if="item.folio_status == '1'">
          <v-chip color="green" dark> Reservasi </v-chip>
        </div>
        <div v-if="item.folio_status == '3'">
          <v-chip color="blue" dark> Buka </v-chip>
        </div>
        <div v-if="item.folio_status == '4'">
          <v-chip color="red" dark> Tutup </v-chip>
        </div>
        <div v-if="item.folio_status == '5'">Batal</div>
      </template>
      <template #[`item.unit_name`]="{ item }">
        <v-chip dark :color="setcolor(item.hari)">
          {{ item.unit_name }} | &nbsp;<b>Hari Rawatan Ke </b>:
          {{ item.hari == 0 ? "1" : item.hari }} Hari
        </v-chip>
      </template>

      <template #[`item.aksi`]="{ item }">
        <v-btn x-small fab dark color="primary" @click="setPasien(item)">
          <v-icon>mdi-check-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="row align-center pb-3 m">
      <div class="col-md-6 col-12 order-md-0 order-1 pt-0 pt-md-4">
        <v-data-footer
          class="float-left"
          :pagination="dataTable.pagination"
          :prev-icon="null"
          :next-icon="null"
          :first-icon="null"
          :last-icon="null"
          :items-per-page-options="[10, 15, 50, 100, -1]"
          :options.sync="dataTable.options"
        />
      </div>
      <div class="col-md-6 col-12 order-md-1 order-0 mt-4 pb-0 pb-md-4">
        <v-pagination
          v-model="dataTable.config.table.page"
          :length="dataTable.config.table.pageCount"
          total-visible="7"
          circle
        />
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { apiKedua, errorMsg } from '../../plugins/supports'
// import debounce from 'lodash/debounce';

// import FormLabor from '@/components/DialogLabor'
// import FormRadiologi from '@/components/DialogRadiologi'
// eslint-disable-next-line no-unused-vars
// import FormBdrs from '../bdrs/FormBdrs'

export default {
  components: {
    // FormLabor,
    // FormBdrs,
    // FormRadiologi
  },
  data () {
    return {
      rentangbulan: [
        '1 Minggu',
        '1 BULAN',
        '2 BULAN',
        '3 BULAN',
        '6 BULAN',
        '1 TAHUN'
      ],
      kerjakan: false,
      sudah_di_kerjakan: [],
      rbulan: '1 Minggu',
      myStylePasien: {
        backgroundColor: '#16a085'
      },
      isDisabled: false,
      statusitem: ['BUKA', 'TUTUP', 'RESERVASI'],
      status: '',
      tab: '',
      // diaglog labor
      dialogShowLabor: false,
      dialogTitleLabor: 'Permintaan Laboratorium',
      dialogProgressLabor: false,
      dialogDisableNBtnLabor: false,
      dialogDisablePBtnLabor: false,

      dialogActionNBtnLabor: () => {
        this.dialogShowLabor = false
      },
      dialogActionPBtnLabor: () => {},

      // Dialog Radiologi
      dialogActionNBtnRadiologi: () => {
        this.dialogShowRadiologi = false
      },
      dialogActionPBtnRadiologi: () => {},

      // Dialog Labor
      dialogActionNBtnBdrs: () => {
        this.dialogShowBdrs = false
      },
      dialogActionPBtnBdrs: () => {},

      dialogShowRadiologi: false,
      dialogShowBdrs: false,
      dialogTitleRadiologi: 'Form Permintaan Radiologi',
      dialogTitleBdrs: 'Form Permintaan Darah',
      dialogMessageRadiologi: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgressRadiologi: false,
      memberCardRadiologi: '',
      dialogDisableNBtnRadiologi: false,
      dialogDisablePBtnRadiologi: false,
      dialogDisableNBtnBdrs: false,
      dialogDisablePBtnBdrs: false,
      itemSeluruh: '',

      dialogShow: false,
      dialogTitle: 'SKRINING PASIEN',
      dialogMessage: 'UNTUK MANAJER PELAYANAN PASIEN',
      dialogProgress: false,
      memberCardV: '',
      dialogDisableNBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogShowSurv: false,
      dialogTitleSurv: 'SURVEILEN HAIs RUANG PERAWATAN EXECUTIVE',
      dialogMessageSurv: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgressSurv: false,
      dataPatient: [],
      dataPatientForm: '',

      dialogDisableNBtnSurv: false,
      dialogActionNBtnSurv: () => {
        this.dialogShowSurv = false
      },
      patient: [],
      iReadonly: false,
      pegawai: '',
      subUnitId: '',
      employeeId: '',
      dataTable: {
        isLoading: false,
        headerData: [
          { text: 'Aksi', value: 'aksi', width: '40px', align: 'center' },
          { text: 'Status', value: 'folio_status', width: '100px' },
          { text: 'Tanggal Masuk', value: 'registration_date', width: '170px' },
          { text: 'Tanggal Pulang', value: 'reg_end_date', width: '170px' },
          { text: 'Nama Pasien', value: 'patient_name', width: '270px' },
          { text: 'DPJP', value: 'staff_name', width: '250px' },
          { text: 'Nama Ruangan', value: 'unit_name', width: '290px' },

          { text: 'No. RM', value: 'medrec_no', width: '120px' },
          { text: 'Cara Bayar', value: 'pay_by_name', width: '250px' },
          { text: 'No. Asuransi/Bpjs', value: 'member_card', width: '150px' },
          { text: 'No. Registrasi', value: 'registration_no', width: '150px' }
        ],
        search: '',
        status: '',
        tglPelayanan: '',
        tglPelayanan2: '',

        datas: [],
        config: {
          table: {
            page: 1,
            pageCount: 0,
            sortBy: ['registration_date'],
            sortDesc: [],
            itemsPerPage: 100,
            itemKey: 'id'
          }
        },
        serverLength: 0,
        pagination: {},
        options: {}
      },

      model: 1,
      snackbar: false,
      snack_text: 'Hello, Im a snackbar',
      folioYangDipakai: '',
      rm: '',
      reg: '',
      folio: '',
      sub_unit_id: '',
      nama_pasien: '',
      pasienSet: ''
    }
  },
  mounted () {
    this.bul = '1 Minggu'
    this.jamSekarang()
    // localStorage.setItem('drawer', 'false')
    // localStorage.setItem('minivar', 'false')
  },
  methods: {
    bulanchange () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.rbulan
      })
    },
    changetanggal () {
      // console.log(this.dataTable.tglPelayanan)
      // console.log(this.dataTable.tglPelayanan2)
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.rbulan,
        tgl: this.dataTable.tglPelayanan,
        tgl1: this.dataTable.tglPelayanan2
      })
    },
    jamSekarang () {
      // const today = new Date()
      // const yyyy = today.getFullYear()
      // let mm = today.getMonth() + 1 // Bulan mulai dari 0, jadi tambahkan 1
      // let dd = today.getDate()

      // if (dd < 10) dd = '0' + dd
      // if (mm < 10) mm = '0' + mm

      // const formattedToday = dd + '/' + mm + '/' + yyyy
      // const todays = yyyy + '-' + mm + '-' + dd

      // // Mendapatkan tanggal satu bulan sebelumnya
      // const oneMonthAgo = new Date()
      // // eslint-disable-next-line no-unused-vars
      // const firstDayOfMonthFormatted = yyyy + '-' + (mm < 10 ? '0' + mm : mm) + '-' + '01'

      // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
      // // eslint-disable-next-line no-unused-vars
      // const yyyyPrev = oneMonthAgo.getFullYear()
      // let mmPrev = oneMonthAgo.getMonth() + 1 // Bulan mulai dari 0, jadi tambahkan 1
      // let ddPrev = oneMonthAgo.getDate()

      // if (ddPrev < 10) ddPrev = '0' + ddPrev
      // if (mmPrev < 10) mmPrev = '0' + mmPrev

      // const formattedOneMonthAgo = todays
      // // const oneMonthAgoFormatted = yyyyPrev + '-' + mmPrev + '-' + ddPrev

      // // Mengisi nilai ke properti Vue
      this.tglSekarang = this.getTodayDate()
      this.dataTable.tglPelayanan = this.getStartOfMonth()
      this.dataTable.tglPelayanan2 = this.getTodayDate()
    },
    getTodayDate () {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0') // Bulan dimulai dari 0
      const day = String(today.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    getStartOfMonth () {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = '01' // Hari pertama bulan
      return `${year}-${month}-${day}`
    },
    muatulang () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.rbulan,
        tgl: this.dataTable.tglPelayanan,
        tgl1: this.dataTable.tglPelayanan2
      })
    },
    setPasienTutup () {},
    setlistpasien () {
      if (this.employeeId !== 'undifined') {
        this.getDataPasien({
          id_pegawai: this.pegawai.employee_id,
          // eslint-disable-next-line eqeqeq
          is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
          ...this.dataTable.options,

          search: this.dataTable.search
        })
      }
    },
    buatBdrs (item) {
      console.log(item)
      this.$router.replace({
        name: 'DataPasienRawatInap',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowBdrs = true
        // this.dataPatient = this.item
        this.$refs.bdrs.getDataPasien(this.$route.query.no_rm)
        this.$refs.bdrs.getDataListBankDarah(this.$route.query.no_rm)
      }, 1000)
    },

    rubah () {
      if (this.employeeId) {
        if (this.dataTable.search.length >= 2) {
          this.getDataPasien({
            id_pegawai: this.pegawai.employee_id,
            // eslint-disable-next-line eqeqeq
            is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
            ...this.dataTable.options,
            search: this.dataTable.search,
            status: this.dataTable.status,
            bulan: this.rbulan,
            tgl: this.dataTable.tglPelayanan,
            tgl1: this.dataTable.tglPelayanan2
          })
        } else {
          errorMsg('Tidak Mencari Jika Tidak Lebih Dari 2 Kata')
        }
      }
    },
    formSurve (v) {
      this.dialogProgressSurv = true

      setTimeout(() => {
        this.dialogProgressSurv = false

        this.dialogShowSurv = true
        this.dataPatient = v
        this.dialogTitleSurv = 'SURVEILEN HAIs RUANG PERAWATAN ' + v.unit_name
        this.$refs.formSurve.patientData()
      }, 1000)
    },
    setcolor (hari) {
      if (hari >= 5) {
        return 'red darken-1'
      } else {
        return 'teal lighten-1'
      }
    },
    itemRowBackground (folioIdNya) {
      if (this.sudah_di_kerjakan.includes(folioIdNya)) {
        return 'green'
      } else {
        return 'red'
      }
    },

    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }
      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },

    getColor (v) {
      // return new Promise((resolve, reject) => {
      // })
    },
    getDataPasien (v) {
      this.dataTable.isLoading = true
      // this.isDisabled = true
      // console.log(v)
      const query = new URLSearchParams(v).toString()
      console.log(query)

      apiKedua
        .get(`rawat-inap/get-data-in-tutup?${query}`)
        .then((response) => {
          this.dataTable.datas = response.data.results
            ? response.data.results.data ?? []
            : []
          this.dataTable.serverLength = response.data.results
            ? parseInt(response.data.results.total) ?? 0
            : 0
          this.isReadonly = false
          this.dataTable.isLoading = false
          // this.isDisabled = false
        })
        .catch((error) => {
          this.isReadonly = false
          this.dataTable.isLoading = false
          // this.isDisabled = false

          console.log(error)
        })
    },
    orderLabor (item) {
      this.$router.replace({
        name: 'DataPasienRawatInap',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowLabor = true
        this.$refs.labor.historyLabor(this.$route.query.no_rm)
      }, 1000)
    },
    statusChange () {
      this.getDataPasien({
        id_pegawai: this.pegawai.employee_id,
        // eslint-disable-next-line eqeqeq
        is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
        ...this.dataTable.options,
        search: this.dataTable.search,
        status: this.dataTable.status,
        bulan: this.bulanchange,
        tgl: this.dataTable.tglPelayanan,
        tgl1: this.dataTable.tglPelayanan2
      })
    },
    orderRadiologi (item) {
      this.$router.replace({
        name: 'DataPasienRawatInap',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
      setTimeout(() => {
        this.dialogShowRadiologi = true
        this.$refs.radiologi.historyRadiologi(this.$route.query.no_rm)
      }, 1000)
    },
    cppt () {},
    setPasien (item) {
      // console.log(item)

      this.$router.push({
        name: 'CPPTRAWATINAP',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
    }
  },
  watch: {
    'dataTable.options' (a, b) {
      if (this.employeeId) {
        setTimeout(() => {
          this.getDataPasien({
            id_pegawai: this.pegawai.employee_id,
            // eslint-disable-next-line eqeqeq
            is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
            ...this.dataTable.options,
            search: this.dataTable.search,
            status: this.dataTable.status,
            bulan: this.rbulan,
            tgl: this.dataTable.tglPelayanan,
            tgl1: this.dataTable.tglPelayanan2
          })
        }, 100)
      }
    }
    // 'dataTable.search' (a, b) {
    //   // setTimeout(() => {

    //   // }, 100)
    // }
    // 'dataTable.status' (a, b) {
    //   setTimeout(() => {
    //     if (this.employeeId !== 'undifined') {
    //       this.getDataPasien({
    //         id_pegawai: this.pegawai.employee_id,
    //         // eslint-disable-next-line eqeqeq
    //         is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
    //         ...this.dataTable.options,
    //         search: this.dataTable.search,
    //         status: this.dataTable.status
    //       })
    //     }
    //   }, 100)
    // }
  },
  created () {
    this.dataTable.status = 'BUKA'
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
        this.subUnitId = this.pegawai.sub_unit_id
        this.employeeId = this.pegawai.employee_id
        if (this.employeeId) {
          // this.isDisabled = true

          this.getDataPasien({
            id_pegawai: this.pegawai.employee_id,
            // eslint-disable-next-line eqeqeq
            is_dokter: this.pegawai.is_dokter == 1 ? 1 : 0,
            ...this.dataTable.options,

            search: this.dataTable.search,
            status: this.dataTable.status,
            bulan: this.rbulan,
            tgl: this.dataTable.tglPelayanan,
            tgl1: this.dataTable.tglPelayanan2
          })
          // this.isDisabled = false
        }

        // console.log(this.subUnitId)
      }, 100)
    }
  }
}
</script>
