<template>
  <div>
    <v-card elevation="2">
      <v-card-title>RABER/ALIH RAWAT</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12" lg="12" sm="12">
            <v-text-field
              v-model="search"
              dense
              label="Cari Nama Pasien"
              class="ma-1"
              outlined
              @keyup="fetchData"
            ></v-text-field>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :server-items-length="total"
              :loading="loading"
              :items-per-page="limit"
              height="750pt"
              fixed-header
              :disable-sort="true"
              @update:page="fetchData"
              @update:options="updatePagination"
            >
              <template #[`item.folio_status`]="{ item }">
                <div v-if="item.folio_status == '1'">
                  <v-chip color="green" dark> Reservasi </v-chip>
                </div>
                <div v-if="item.folio_status == '3'">
                  <v-chip color="blue" dark> Buka </v-chip>
                </div>
                <div v-if="item.folio_status == '4'">
                  <v-chip color="red" dark> Tutup </v-chip>
                </div>
                <div v-if="item.folio_status == '5'">Batal</div>
              </template>
              <template #[`item.aksi`]="{ item }">
                <v-btn
                  x-small
                  fab
                  dark
                  color="primary"
                  @click="setPasien(item)"
                >
                  <v-icon>mdi-check-circle-outline</v-icon>
                </v-btn>
              </template>
              <template #[`item.unit_name`]="{ item }">
                    <v-chip dark :color="setcolor(item.hari)">
                      {{ item.unit_name }} | &nbsp;<b>Hari Rawatan Ke </b>:
                      {{ item.hari == 0 ? "1" : item.hari }} Hari
                    </v-chip>
                  </template>

              <template #[`item.dpjp_utama`]="{ item }">
                {{ item.dpjp_utama }}
                <br />
                <v-chip v-if="item.namaDokterRaber" dark color="pink lighten-3">
                  Raber/Konsul : {{ item.namaDokterRaber }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { baseUrl } from '@/plugins/supports'

export default {
  data () {
    return {
      headers: [
        // Define your table headers here
        { text: 'Aksi', value: 'aksi', width: '190px', align: 'center' },
        { text: 'Status', value: 'folio_status', width: '100px' },
        { text: 'Nama Pasien', value: 'patient_name', width: '270px' },
        { text: 'DPJP UTAMA', value: 'dpjp_utama', width: '250px' },
        { text: 'Nama Ruangan', value: 'unit_name', width: '290px' },
        { text: 'Tanggal Masuk', value: 'service_date', width: '170px' },
        { text: 'No. RM', value: 'medrec_no', width: '120px' },
        { text: 'Cara Bayar', value: 'pay_by_name', width: '250px' },
        { text: 'No. Asuransi/Bpjs', value: 'member_card', width: '150px' }
      ],
      items: [],
      search: '',
      tglPelayanan: '',
      loading5: false,
      search2: '',
      total: 0,
      page: 1,
      limit: 15,
      loading: false,
      employeeId: '',
      pegawai: '',
      subUnitId: ''
    }
  },
  created () {
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
        this.subUnitId = this.pegawai.sub_unit_id
        this.employeeId = this.pegawai.employee_id

        this.fetchData()
        // console.log(this.subUnitId)
      }, 100)
    }
  },
  methods: {
    fetchData () {
      // console.log(this.search2)
      this.loading = true
      const url =
        baseUrl +
        `rawat-inap/get-data-raber?page=${this.page}&limit=${this.limit}&search=${this.search}&id_pegawai=${this.employeeId}`
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.items = data.items
          this.total = data.total
          this.loading = false
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },
    setcolor (hari) {
      if (hari >= 5) {
        return 'red darken-1'
      } else {
        return 'teal lighten-1'
      }
    },
    updatePagination (options) {
      this.limit = options.itemsPerPage
      this.page = options.page
      this.fetchData()
    },
    setPasien (item) {
      console.log(item)

      this.$router.push({
        name: 'CPPTRAWATINAP',
        query: {
          no_rm: item.medrec_no,
          folio_id: item.folio_id,
          registration_id: item.registration_id,
          sub_unit_id: item.sub_unit_id,
          type: 'rawat-inap'
        }
      })
    }
  }
}
</script>
